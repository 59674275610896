// extracted by mini-css-extract-plugin
export var section = "join-us-screen-module--section--Ch+oG";
export var deco = "join-us-screen-module--deco--tQnen";
export var deco2 = "join-us-screen-module--deco-2--YeoK1";
export var deco1 = "join-us-screen-module--deco-1--RJuDE";
export var title = "join-us-screen-module--title--2A5AJ";
export var description = "join-us-screen-module--description--ofGyx";
export var buttonIcon = "join-us-screen-module--button-icon--ygOuw";
export var buttonBigger = "join-us-screen-module--button-bigger--sV4e2";
export var buttonArrow = "join-us-screen-module--button-arrow--Dn7wi";
export var buttonArrowSecond = "join-us-screen-module--button-arrow-second--IMRoh";
export var buttonArrowLeft = "join-us-screen-module--button-arrow-left--Jz55A";
export var buttonStill = "join-us-screen-module--button-still--UZLPi";
export var bottomArrow = "join-us-screen-module--bottom-arrow--etKUr";
export var containerLink = "join-us-screen-module--container-link--CQQtD";